import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/routes";
import store from "@/state/store/index.js";
import "@/state/helpers/index.js";
import "@/design/index.scss";

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueTranslate from "vue-translate-plugin";
Vue.use(VueTranslate);

import VueNoty from "vuejs-noty";
Vue.use(VueNoty, {
  theme: "sunset",
});

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueMask from "v-mask";
Vue.use(VueMask);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

Vue.use(require("vue-moment"));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
